<template>
	<div class="main">
		
		<div class="top">
			<div class=""> {{$t('recommend.please')}} </div>
			<!-- <div class="time">{{orderInfo.time}}</div> -->
			<div class="time">
				<!-- <el-statistic ref="statistic" @finish="hilarity" format="mm:ss" :value="orderInfo.time" time-indices > </el-statistic> -->
				<div style="color: #3370ff; display: inline; margin-left: 5px;display: flex;">
				  {{toLiveBtn }}
				  <!-- <el-statistic ref="statistic" @finish="hilarity" format="HH:mm:ss" :value="time" time-indices> </el-statistic> -->
				</div>
			</div>
			<div class="">{{$t('recommend.orderCloseTitle')}} </div>
		</div>
		<div class="content" v-if="type == '1'">
			<div class="left">
				<img class="code-img" v-if="orderInfo.codeImg" :src="orderInfo.codeImg" >
				<img class="logo-img" v-if="!orderInfo.codeImg && themeStyle == 'dark'" src="../../assets/images/main/logo-white.png" >
				<img class="logo-img" v-if="!orderInfo.codeImg && themeStyle == 'default'" src="../../assets/images/main/logo-dark.png" >
				<!-- <img class="logo-img" v-if="themeStyle == 'dark' && orderInfo.codeImg == ''" src="@/assets/images/main/logo-white.png" > -->
				<!-- <img src="/src/assets/images/main/logo-white.png" > -->
				
				<!-- <img src="../../assets/images/main/logo-dark.png" > -->
			</div>
			<div class="right">
				<div class="amount-title">{{$t('recommend.amountPayable')}}</div>
				<div class="amount-money">
					<div class="icon">￥</div>
<!--					<div class="number">{{orderInfo.money ? Math.ceil(orderInfo.money) : '0'}}</div>-->
					<div class="number">{{orderInfo.money ? orderInfo.money : '0'}}</div>
				</div>
				<div class="pay-text">{{$t('recommend.paymentMethod')}}</div>
				<div v-if="orderInfo.isWechat == 1" class="pay-wechat">
					<img src="@/assets/images/recommend/wechat.png" alt="">
					<div @click="wechatPay" class="pay-wechat-text" :class="{'active': current == 0}">{{$t('recommend.weChatPayment')}}</div>
				</div>
				<div v-if="orderInfo.isApliy == 1" class="pay-apliy">
					<img src="@/assets/images/recommend/apliy.png" alt="">
					<div @click="alipayPay" class="pay-apliy-text" :class="{'active': current == 1}">{{$t('recommend.alipayPayment')}}</div>
				</div>
			</div>
		</div>
		<div class="content1" v-if="type == '2'">
			<!-- <div class="wrap"> -->
				<div class="left">
					<div class="amount-title">{{$t('recommend.amountPayable')}}</div>
					<div class="amount-money">
						<div class="icon">￥</div>
						<div class="number">{{orderInfo.money ? Math.ceil(orderInfo.money) : '0'}}</div>
					</div>
				</div>
				<div class="middle">
					<div class="pay-text">{{$t('recommend.paymentMethod')}}</div>
					<div v-if="orderInfo.isWechat == 1" class="pay-wechat">
						<img src="@/assets/images/recommend/wechat.png" alt="">
						<div @click="wechatPay" :class="{'active': current == 0}" class="pay-wechat-text">{{$t('recommend.weChatPayment')}}</div>
					</div>
					<div v-if="orderInfo.isApliy == 1" class="pay-apliy">
						<img src="@/assets/images/recommend/apliy.png" alt="">
						<div @click="alipayPay" :class="{'active': current == 1}" class="pay-apliy-text">{{$t('recommend.alipayPayment')}}</div>
					</div>
					<div v-if="orderInfo.isCorporateTransfer == 1" class="pay-trafer">
						<img src="@/assets/images/recommend/transfer.png" alt="">
						<div @click="transferPay" :class="{'active': current == 2}" class="pay-apliy-text">{{$t('recommend.corporateTransfer')}}</div>
					</div>
				</div>
				<div class="right" v-if="current == 2">
					<div class="info">
						<div class="info-title">{{$t('recommend.corporateTransferInfo')}}</div>
						<div class="corporate-name">{{$t('recommend.corporateName')}}：{{orderInfo.corporateName}}</div>
						<div class="bank-deposit">{{$t('recommend.bankDeposit')}}：{{orderInfo.bankDeposit}}</div>
						<div class="bank-account">{{$t('recommend.bankAccount')}}：{{orderInfo.bankAccount}}</div>
					</div>
					<div class="upload">
						<div class="upload-title">{{$t('recommend.paymentVoucher')}}</div>
						<div>
							<el-upload
							    class="avatar-uploader"
							    :action="uploadImageUrl"
								name="image"
								:show-file-list="false"
							    :on-success="callbackcover"
							    :before-upload="testformatCover"
								ref="cover">
							  <img v-if="imageUrl" :src="imageUrl" class="avatar">
							  <div v-else>
								  <i class="el-icon-plus avatar-uploader-icon"></i>
								  <div class="el-upload__tip">{{$t('recommend.clickUpload')}}</div>
								  <div class="el-upload__tip">jpg/png/jpeg{{$t('recommend.format')}}</div>
							  </div>
							  
							</el-upload>
							
							<!-- <el-upload
							    class="avatar-uploader"
							    :action="uploadUrl"
								name="image"
							    :show-file-list="false"
							    :on-success="handleAvatarSuccess"
							    :before-upload="beforeAvatarUpload"
								ref="cover">
							  <img v-if="ruleForm.image  == ''" class="avatar-img" src="https://fuhuiduoduo.axa2.com/upload/static/icon-touxiang@2x(1).png" alt="">
							  <img v-if="ruleForm.image != ''" class="avatar-img" :src="ruleForm.image" alt="">
							</el-upload> -->
						</div>
					</div>
				</div>
				<div v-if="current != 2" style="display: flex;align-items: center;">
					<!-- <img style="width: 190px;height: 190px;border-radius: 6px;" :src="orderInfo.codeImg" > -->
					<img class="code-img" v-if="current != 2 && orderInfo.codeImg" :src="orderInfo.codeImg" >
					<img class="logo-img" v-if="current != 2 && !orderInfo.codeImg && themeStyle == 'dark'" src="../../assets/images/main/logo-white.png" >
					<img class="logo-img" v-if="current != 2 && !orderInfo.codeImg && themeStyle == 'default'" src="../../assets/images/main/logo-dark.png" >
				</div>
				
				<!-- <img class="code-img" v-if="current != 2 && orderInfo.codeImg" :src="orderInfo.codeImg" >
				<img class="logo-img" v-if="current != 2 && !orderInfo.codeImg && themeStyle == 'dark'" src="../../assets/images/main/logo-white.png" >
				<img class="logo-img" v-if="current != 2 && !orderInfo.codeImg && themeStyle == 'default'" src="../../assets/images/main/logo-dark.png" > -->
			<!-- </div> -->
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			orderInfo: {
				type: Object,
				default(){
					return {}
				}
			},
			type: {
				type: String,
				default: '1',
			}
		},
		data(){
			return {
				imageUrl: '',
				current: -1,//控制选中 支付宝 微信
				payType: '',//支付方式 1支付宝 2微信
				uploadImageUrl:process.env.VUE_APP_UPLOAD_IMAGE_URL, //上传图片路径
				imageUrl: '',
				image1: '',
				countTime: '',
				_interval: '',
				toLiveBtn: '',
				// themeStyle: '',
				themeStyle: localStorage.getItem("theme")
			}
		},
		created(){
			
			let that = this
			that.countTime = that.orderInfo.time
			that.countdown()
			window.addEventListener("setItemEvent", function(e) {
				console.log(e.newValue.length,'555555555555555');
				if(e.newValue.length > 10){
					if((typeof JSON.parse(e.newValue) == 'object')){
					}
				}else {
					if(e.newValue == 'default' || e.newValue == 'dark'){
						that.themeStyle = e.newValue;
					}
					
					if(e.newValue == 'en' || e.newValue == 'cn' || e.newValue == 'ko'){
						that.$router.go(0)
						console.log('6666666666666666')
					}
				}
				
				
				// that.themeName = e.newValue;
				// that.themeName = localStorage.getItem("theme");
			});
		},
		methods:{
			// 倒计时事件
			countdown() {
			  const that = this
			  that._interval = setInterval(() => {
			    if (that.countTime == 0) {
			      // 计时结束，清除缓存
			      clearInterval(that._interval)
			    } else {
			      that.countTime--
			      let day = parseInt(that.countTime / 60 / 60 / 24)
			      let hr = parseInt(that.countTime / 60 / 60 % 24)
			      let min = parseInt(that.countTime / 60 % 60)
			      let sec = parseInt(that.countTime % 60)
			      day = day > 9 ? day : '0' + day
			      hr = hr > 9 ? hr : '0' + hr
			      min = min > 9 ? min : '0' + min
			      sec = sec > 9 ? sec : '0' + sec
			
			     let newhr = parseInt(hr)+ parseInt(day*24)
			      that.toLiveBtn = `${min}:${sec}`
				  // console.log(that.toLiveBtn,'倒计时')
			    }
			  }, 1000);
			},
			// 上传图片成功
			callbackcover(res,file) {
				console.log(res, 'rrrrrrrrrr')
				// this.imageUrl = process.env.VUE_APP_FILE_PATH + '/' + res.data.url;
				this.imageUrl = URL.createObjectURL(file.raw);
				this.image1 = res.data.url
				this.$emit('getvoucherImage',this.image1)
				console.log(this.imageUrl)
			},
			// 移除图片
			handleRemove(file) {
				this.$refs.upload.clearFiles();
			},
			testformatCover(file) {
				const cover_isJPG = file.type === "image/jpeg";
				const cover_isPNG = file.type === "image/png";
				const cover_isLt10M = file.size / 1024 / 1024 < 10;
				if (cover_isJPG || cover_isPNG) {
					if (!cover_isLt10M) {
						this.$message({
							type: "error",
							message: this.$t("Collaboration.toobig"),
						});
						return false;
					} else {}
				} else {
					this.$message({
						type: "error",
						message: this.$t("sampleup_rightcover"),
					});
					return false;
				}
			},
			wechatPay(){
				
				this.current = 0
				this.payType = 2
				this.$emit('wechatPay',this.payType)
			},
			alipayPay(){
				this.current = 1
				this.payType = 1
				this.$emit('alipayPay',this.payType)
			},
			transferPay(){
				this.current = 2
				this.payType = 4
				this.$emit('transferPay',this.payType)
			},
			// hilarity() {
			//       this.$notify({
			//           title: '提示',
			//           message: '时间已到，你可知寸金难买寸光阴？',
			//           duration: 0
			//         });
			//     },
		}
	}
</script>

<style lang="less" scoped>
	.logo-img{
		width: 158px;
		height: 68px;
		// border-radius: 6px;
		margin-right: 60px;
	}
	.code-img{
		width: 190px;
		height: 190px;
		border-radius: 6px;
		margin-right: 60px;
	}
	.main{
		// width: 938px;
		// height: 391px;
		// height: 300px;
		background: @recommendMusicianListBGColor;
		border-radius: 6px;
		.top{
			display: flex;
			padding: 14px 29px 0 29px;
			color:  @headerTextColor;
			font-size: 16px;
			font-weight: 'Semibold';
			.time {
				color: #3370FF;
				padding: 0 4px;
			}
			::v-deep .time .el-statistic .con .number{
				color: #3370FF;
			}
		}
		.content{
			display: flex;
			justify-content: center;
			padding: 20px 0;
			.left{
				display: flex;
				align-items: center;
				.logo-img{
					width: 158px;
					height: 68px;
					// border-radius: 6px;
					margin-right: 60px;
				}
				.code-img{
					width: 190px;
					height: 190px;
					border-radius: 6px;
					margin-right: 60px;
				}
			}
			.right{
				margin-left: 24px;
				.amount-title{
					font-size: 16px;
					font-weight: 'Medium';
				}
				.amount-money{
					display: flex;
					font-size: 40px;
					color: #3370FF;
					margin-top: 16px;
					margin-bottom: 24px;
					align-items: flex-end;
					.icon{
						font-size: 30px;
					}
				}
				.pay-text {
					color:  @headerTextColor;
					font-size: 16px;
					margin-bottom: 16px;
				}
				.pay-wechat{
					display: flex;
					align-items: center;
					margin-bottom: 8px;
					cursor: pointer;
					
					img{
						width: 30px;
						height: 30px;
						border-radius: 50%;
						margin-right: 16px;
					}
					.pay-wechat-text{
						color:  @headerTextColor;
						font-size: 16px;
						&:hover{
							color: #3370FF;
						}
						&:active{
							color: #3370FF;
						}
						// &:focus{
						// 	color: red;
						// }
					}
					.active{
						color: #3370FF;
					}
				}
				.pay-apliy{
					display: flex;
					align-items: center;
					img{
						width: 30px;
						height: 30px;
						border-radius: 50%;
						margin-right: 16px;
					}
					.pay-apliy-text{
						color:  @headerTextColor;
						font-size: 16px;
						&:hover{
							color: #3370FF;
						}
						&:active{
							color: #3370FF;
						}
					}
					.active{
						color: #3370FF;
					}
				}
				
			}
		}
		.content1{
			display: flex;
			justify-content: center;
			padding: 10px 0;
			.left{
				margin-right: 56px;
				.amount-title{
					font-size: 16px;
					font-weight: 'Medium';
				}
				.amount-money{
					display: flex;
					font-size: 40px;
					color: #3370FF;
					margin-top: 16px;
					margin-bottom: 24px;
					align-items: flex-end;
					.icon{
						font-size: 30px;
					}
				}
			}
			.middle{
				margin-right: 50px;
				.pay-text {
					color:  @headerTextColor;
					font-size: 16px;
					margin-bottom: 16px;
				}
				.pay-wechat{
					display: flex;
					align-items: center;
					margin-bottom: 8px;
					cursor: pointer;
					img{
						width: 30px;
						height: 30px;
						border-radius: 50%;
						margin-right: 16px;
					}
					.pay-wechat-text{
						color:  @headerTextColor;
						font-size: 16px;
						&:hover{
							color: #3370FF;
						}
						&:active{
							color: #3370FF;
						}
					}
					.active{
						color: #3370FF;
					}
				}
				.pay-apliy{
					display: flex;
					align-items: center;
					cursor: pointer;
					img{
						width: 30px;
						height: 30px;
						border-radius: 50%;
						margin-right: 16px;
					}
					.pay-apliy-text{
						color:  @headerTextColor;
						font-size: 16px;
						&:hover{
							color: #3370FF;
						}
						&:active{
							color: #3370FF;
						}
					}
					.active{
						color: #3370FF;
					}
				}
				.pay-trafer{
					display: flex;
					align-items: center;
					margin-top: 8px;
					cursor: pointer;
					
					img{
						width: 30px;
						height: 30px;
						border-radius: 50%;
						margin-right: 16px;
					}
					.pay-apliy-text{
						color:  @headerTextColor;
						font-size: 16px;
						&:hover{
							color: #3370FF;
						}
						&:active{
							color: #3370FF;
						}
					}
					.active{
						color: #3370FF;
					}
				}
				
			}
			
			.right{
				.info{
					// line-height: 36px;
					margin-bottom: 14px;
					.info-title{
						
					}
					.corporate-name{
						// line-height: 36px;
					}
					.bank-deposit{
						// line-height: 36px;
					}
					.bank-account{
						// line-height: 36px;
					}
				}
				// width: 90px;
				// display: flex;
				// flex-direction: column;
				.upload{
					.upload-title{
						line-height: 36px;
					}
				}
				// 上传样式
				::v-deep .avatar-uploader {
				  display: flex;
				  width: 148px;
				}
				
				::v-deep .avatar-uploader .el-upload {
				  // border: 1px dashed #fff;
				  border: 1px dashed @headerSelectTextColor;
				  border-radius: 6px;
				  cursor: pointer;
				  position: relative;
				  overflow: hidden;
				}
				
				::v-deep .avatar-uploader .el-upload:hover {
				  border-color: #409EFF;
				}
				
				.avatar-uploader-icon {
				  font-size: 28px;
				  color: @headerSelectTextColor;
				  width: 148px;
				  height: 60px;
				  line-height: 80px;
				  text-align: center;
				
				  &:hover {
				    color: #409EFF;
				  }
				}
				
				.avatar {
				  width: 148px;
				  height: 106px;
				  display: block;
				}
				
				::v-deep .el-upload__tip {
				  // padding-top: 64px;
				  margin-left: 8px;
				  line-height: 16px;
				}
				
			}
		}
	}
</style>